.root {
    overflow: hidden;
    position: relative;
    z-index: 0;
}

.background {
    position: fixed;
    z-index: -100;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    background-color: black;
    background-image: url("../assets/images/background.jpg");
    background-size: cover;
}

.portal {
    position: relative;
    max-width: 414px;
    height: 500px;
    margin: -160px auto -50px auto;
}

.portalVideo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    mix-blend-mode: lighten;
}

.portalBackground {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 120%;
    max-width: none;
}

.portalForeground {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 120%;
    max-width: none;
}

.portalCharacters {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 120%;
    max-width: none;
}