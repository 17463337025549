:root {
  --brand-red: #f71b1b;
  --brand-orange: #ff8e00;
  --brand-yellow: #fefa00;
  --brand-blue: #1b09ff;
  --brand-pink: #ff0067;
  --brand-br-pink: #f04e98;
  --brand-br-brown: #3f2021;
  --brand-dark-green: #282800;
}
