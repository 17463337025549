.button {
    position: fixed;
    z-index: 1;
    bottom: 0;
    left: 0;
    display: grid;
    place-content: center;
    grid-template-columns: 1;
    grid-template-rows: 1;
    margin: 20px 47px;
}

.indicator {
    position: absolute;
    z-index: -1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}