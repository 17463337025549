.button {
    position: fixed;
    z-index: 1;
    // top: 0;
    // right: 0;
    bottom: 0;
    left: 50%;
    margin: 20px 0;
    transform: translateX(-50%);
    // pointer-events: none;
}