.root {
    overflow: hidden;
    position: relative;
    z-index: 0;
    min-height: 100dvh;
}

.background {
    position: fixed;
    z-index: -100;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    background-color: black;
    background-image: url("../assets/images/background-landing.jpg");
    background-size: cover;
}

.items {
    display: grid;
    color: white;
}

.item {
    grid-column: 1;
    grid-row: 1;
    opacity: 0;
    transition-duration: 1s;
    transition-property: opacity;
}

.itemActive {
    opacity: 1;
}