.layerBackground {
    position: relative;
    z-index: 2;

    &:after {
        content: attr(data-content);
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        transform: translateX(-6px) translateY(5px);
        z-index: -1;
        transition: all ease-in-out 0.2s;
    }

    &:before {
        content: attr(data-content);
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        transform: translateX(4px) translateY(-2px);
        z-index: -1;
        transition: all ease-in-out 0.2s;
    }
}

.layerBackgroundNormal {
    color: var(--brand-blue);
}

.layerBackgroundCritical {
    color: var(--brand-red);
}

.layerForeground {
    position: relative;
    z-index: 2;
    color: var(--brand-yellow);
    filter: drop-shadow(0px 0px 3px var(--brand-yellow)) drop-shadow(0px 0px 6px var(--brand-orange));
}