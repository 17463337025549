@tailwind base;
@tailwind components;
@tailwind utilities;

@import "./fonts/BaskinRobbinsOmny.scss";
@import "./fonts/CCComicrazy.scss";
@import "./fonts/PawlSkinnySemibold.scss";
// @import "./fonts/NeoSansArabic.scss";

@import "./variables.scss";
// @import "./panels.scss";

html,
body {
  // color: white;
  background-color: black;
}

.menu {
  background-image: url("../images/elements/menu-bg.png");
  background-size: 694px;
  background-position: center top;
  background-repeat: no-repeat;
}

.dots-bg {
  background-repeat: repeat-x;
  background-position: center;
  background-size: 4px;

  &--yellow {
    background-image: url("../images/elements/dots-bg-yellow.png");
  }

  &--orange {
    background-image: url("../images/elements/dots-bg-orange.png");
  }
}

.layeredHeading {
  .layerBackground {
    position: relative;
    z-index: 2;

    &:after {
      // Blue text
      content: attr(data-content);
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      transform: translateX(-6px) translateY(5px);
      color: var(--brand-blue);
      z-index: -1;
      transition: all ease-in-out 0.2s;
    }

    &:before {
      // Pink text
      content: attr(data-content);
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      transform: translateX(4px) translateY(-2px);
      color: var(--brand-pink);
      z-index: -1;
      transition: all ease-in-out 0.2s;
    }
  }

  .layerForeground {
    position: relative;
    z-index: 2;
    color: white;

    &:after {
      // Pink outline
      content: attr(data-content);
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      transform: translateX(-2px) translateY(2px);
      z-index: 2;
      -webkit-text-fill-color: transparent;
      -webkit-text-stroke-width: 1.5px;
      -webkit-text-stroke-color: var(--brand-red);
      transition: all ease-in-out 0.2s;
    }
  }

  &--dark {
    .layerForeground {
      color: black;

      &:before {
        // White outline
        content: attr(data-content);
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        transform: translateX(1px) translateY(2px);
        z-index: 2;
        text-shadow: -1px -1px 0 #fff, 0 -1px 0 #fff, 1px -1px 0 #fff,
          1px 0 0 #fff, 1px 1px 0 #fff, 0 1px 0 #fff, -1px 1px 0 #fff,
          -1px 0 0 #fff;
        transition: all ease-in-out 0.2s;
      }
    }
  }

  &--hoverable {
    .layerBackground {

      &:before,
      &:after {
        opacity: 0;
      }
    }

    .layerForeground {
      color: white;

      &:before,
      &:after {
        opacity: 0;
      }
    }

    &:hover {
      .layerBackground {

        &:before,
        &:after {
          opacity: 1;
        }
      }

      .layerForeground {
        color: black;

        &:before,
        &:after {
          opacity: 1;
        }
      }
    }
  }
}

// Custom

select {
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16.25 7.5L10 13.75L3.75 7.5' stroke='black' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  background-position: right 8px center;
  background-repeat: no-repeat;
  background-size: 20px;
}

.item-enter {
  opacity: 0;
}

.item-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.item-enter-done {
  opacity: 1;
}

.item-exit {
  opacity: 1;
}

.item-exit-active {
  opacity: 0;
  transition: opacity 500ms ease-in;
}

.item-exit-done {
  opacity: 0;
}

// Height

.h-viewport {
  height: var(--vvh);
}