@use 'sass:math';

.root {
    position: fixed;
    z-index: 1;
    top: 0;
    right: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
}

.gauge {
    position: relative;
    display: inline-flex;
    width: 414px;
    margin: auto;
}

.svg {}

.svgTexture {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 50%;
    width: 100vw;
    height: 100%;
    transform: translateX(-50%);
}

.points {
    position: absolute;
    z-index: 0;
    display: grid;
    place-content: center;
    top: 22%;
    left: 50%;
    transform: translateX(-50%);
    font-style: normal;
    font-weight: 400;
    font-size: 48px;
    line-height: 1;
    text-align: center;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #FEFA00;
    text-shadow: 0px 0px 3px #FEFA00, 0px 0px 6px #FF8E00;

    @media (max-width: 414px) {
        font-size: math.div(48px, 414px) * 100vw;
    }
}

.time {
    position: absolute;
    z-index: 0;
    display: grid;
    place-content: center;
    top: 28.5%;
    left: 12.5%;
    width: 21.5%;
    height: 28.5%;
    font-style: normal;
    font-weight: 400;
    font-size: 21px;
    line-height: 1;
    text-align: center;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #FFFFFF;
    text-shadow: -2px 2px 8px rgba(40, 40, 0, 0.6);

    @media (max-width: 414px) {
        font-size: math.div(21px, 414px) * 100vw;
    }
}

.timeProgress {
    position: absolute;
    z-index: -1;
    top: -11%;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: skewX(45deg) scaleX(1);
    transform-origin: left;
    background-color: #FEFA00;
    box-shadow: 0px 0px 3px #FEFA00, 0px 0px 6px #FF8E00;
}

.timeProgressLevel1 {
    background-color: #FF8E00;
}

.timeProgressLevel2 {
    background-color: #F71B1B;
}

.bullets {
    position: absolute;
    z-index: 0;
    top: 24.5%;
    left: 66%;
    width: 21.5%;
    height: 28.5%;
    color: white;
}

.bulletsContainer {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    column-gap: 3%;
    transform: skewX(-45deg);
}

.bulletsContainerDisabled {
    opacity: 0.2;
}

.bulletsReload {
    transform: translate(57%, 14.5%);
}

.bullet {
    >path:nth-of-type(1) {
        fill: #FEFA00;
    }

    >path:nth-of-type(2) {
        fill: #282800;
        fill-opacity: 1;
    }
}

.bulletLevel1 {
    >path:nth-of-type(1) {
        fill: #FF8E00;
    }
}

.bulletLevel2 {
    >path:nth-of-type(1) {
        fill: #F71B1B;
    }
}

.bulletEmpty {
    >path:nth-of-type(1) {
        fill: #282800;
    }

    >path:nth-of-type(2) {
        fill: #FEFA00;

    }
}

.comboItem {
    >path:nth-of-type(1) {
        fill: #282800;
        fill-opacity: 0.6;
    }

    >path:nth-of-type(2) {
        fill: #FEFA00;
        // fill-opacity: 1;
    }
}

.comboItemLevel1 {
    opacity: 1;

    >path:nth-of-type(1) {
        fill: #FEFA00;
        fill-opacity: 1;
    }

    >path:nth-of-type(2) {
        fill: #282800;
        // fill-opacity: 1;
    }
}

.comboItemLevel2 {
    opacity: 1;

    >path:nth-of-type(1) {
        fill: #FF8E00;
        fill-opacity: 1;
    }

    >path:nth-of-type(2) {
        fill: #282800;
        // fill-opacity: 1;
    }
}

.comboItemLevel3 {
    opacity: 1;

    >path:nth-of-type(1) {
        fill: #F71B1B;
        fill-opacity: 1;
    }

    >path:nth-of-type(2) {
        fill: #282800;
        // fill-opacity: 1;
    }
}

.comboMultiplier {
    position: absolute;
    z-index: 0;
    display: grid;
    place-content: center;
    top: 67%;
    left: 74%;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 1;
    text-align: center;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: transparent;
    // text-shadow: 0px 0px 3px #FEFA00, 0px 0px 6px #FF8E00;
    -webkit-text-stroke: 1px #FEFA00;

    @media (max-width: 414px) {
        font-size: math.div(14px, 414px) * 100vw;
    }
}

.comboMultiplierEnabled {
    color: #FEFA00;
    -webkit-text-stroke: none;
}

.comboMultiplierBackgroundEnabled {
    fill: #F71B1B;
    fill-opacity: 1;
}