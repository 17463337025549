@font-face {
  font-family: 'Baskin Robbins Omny';
  src: url('../../fonts/BaskinRobbinsOmny/BaskinRobbinsOmny-Black.woff2') format('woff2'),
    url('../../fonts/BaskinRobbinsOmny/BaskinRobbinsOmny-Black.woff') format('woff');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Baskin Robbins Omny';
  src: url('../../fonts/BaskinRobbinsOmny/BaskinRobbinsOmny-Light.woff2') format('woff2'),
    url('../../fonts/BaskinRobbinsOmny/BaskinRobbinsOmny-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Baskin Robbins Omny';
  src: url('../../fonts/BaskinRobbinsOmny/BaskinRobbinsOmny-Bold.woff2') format('woff2'),
    url('../../fonts/BaskinRobbinsOmny/BaskinRobbinsOmny-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Baskin Robbins Omny';
  src: url('../../fonts/BaskinRobbinsOmny/BaskinRobbinsOmny-Thin.woff2') format('woff2'),
    url('../../fonts/BaskinRobbinsOmny/BaskinRobbinsOmny-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Baskin Robbins Omny';
  src: url('../../fonts/BaskinRobbinsOmny/BaskinRobbinsOmny-Regular.woff2') format('woff2'),
    url('../../fonts/BaskinRobbinsOmny/BaskinRobbinsOmny-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Baskin Robbins Omny';
  src: url('../../fonts/BaskinRobbinsOmny/BaskinRobbinsOmny-Medium.woff2') format('woff2'),
    url('../../fonts/BaskinRobbinsOmny/BaskinRobbinsOmny-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Baskin Robbins Omny';
  src: url('../../fonts/BaskinRobbinsOmny/BaskinRobbinsOmny-SemiBold.woff2') format('woff2'),
    url('../../fonts/BaskinRobbinsOmny/BaskinRobbinsOmny-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}